import firebase from "firebase/app";
import "firebase/auth";
import axios from 'axios';

export const getFrame = async () => {

    const token = await firebase.auth().currentUser.getIdToken();
    let url = 'PartnersConsole/iframeUrl';
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
      });
      if (response.status === 200 && response.data) {
        console.log(response.data);
        return response.data;
      } else {
        alert("Une erreur s'est produite, merci de réessayer.");
        return null;
      }
    } catch (e) {
      alert("Une erreur s'est produite, merci de réessayer.");
      return null;
    }
  
    return null;
  };