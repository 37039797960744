/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import LoginForm from "components/LoginForm/LoginForm";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import firebase from "firebase/app";
import axios from "axios";
import constants from "./constants";

var firebaseConfig = {
  apiKey: "AIzaSyBSpSQlZwzbEg8RPjtHecL3WFu1eMeVMbE",
  authDomain: "banky-7d2a0.firebaseapp.com",
  databaseURL: "https://banky-7d2a0.firebaseio.com",
  projectId: "banky-7d2a0",
  storageBucket: "banky-7d2a0.appspot.com",
  messagingSenderId: "318014395948",
  appId: "1:318014395948:web:b43978c950b44f590fe389"
};

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = constants.DEV_API_URL;
} else {
  axios.defaults.baseURL = constants.API_URL;
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/login" render={(props) => (<div>{<LoginForm />}</div>)} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
