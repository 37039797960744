import React, { useState, useEffect } from "react";
import firebase from "@firebase/app";
import Loader from "react-loader-spinner";
import {getFrame} from "./../services/PartnerService"
function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [frame, setFrame] = useState([]);
  const [FrameLoading, setFrameLoading] = useState(false);

  function onChange(user) {
    if (!user) {
      window.location.href = "/";
    }
    setLoading(false);
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  const loadFrame= async () => {
    if (!loading) {
        setFrameLoading(true)
        const res = await getFrame();
        setFrame(res);
        setFrameLoading(false)

    }
  }

  useEffect(async () => {
    loadFrame();
  }, [loading]);

  if(loading || FrameLoading){ 
    return (
    <div style={{ flex: 1, textAlign: "center" }}>
      <Loader type="TailSpin" color="#00BFFF" height={100} width={100} />
    </div>
  );
}

  if (!frame) {
    console.log("No frame");
    return null;
  }
  //https://app.powerbi.com/view?r=eyJrIjoiYjQ4YzQ5MTgtZDdhNS00MzdjLWI3OTktZjk5NjhhMDg3YmQ2IiwidCI6Ijg2MTJjMjI5LTFmNzYtNDcyZi05Zjc0LWI5ZTA4ZDliZTc1MyJ9
  return (
    <iframe frameborder="0" style={{display:"block", height: '80vh', width: '100%'}}
      src={frame}>
    </iframe>
  );
}

export default Dashboard;
